//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Utils from "../js/utils";
export default {
    name: "control-sheet-settings",
    prop: ['mapping'],
    data() {
        return {
            init: true,
            buttons :{
                big_a: 1,
                big_b: -1,
                small_a: 5,
                small_b: -5
            }
        }
    },
    mounted() {
        this.$f7ready(f7 =>{

            // DETECT IF EXISTS CONFIG
            const self = this
            const settings = Utils.getSettingsButtons()
            if (settings !== false){
                // WRITE CONFIG
                this.buttons = settings
            }
            this.updateDomSelect()
            // Unlock Updates
            setTimeout(()=>{
                this.init = false
            },1000)

            // TWO WAYS/SYNC TO PARENT UPDATES
            this.$emit("update:mapping", this.buttons)
        })

    },
    methods: {
      onChange(e){
          //console.log(e.target.selectedOptions[0].value)
          this.buttons[e.target.name]= parseInt(e.target.selectedOptions[0].value)
      },
      resetButtons(e){
          this.buttons={ big_a: 1, big_b: -1, small_a: 5, small_b: -5 }
          this.updateDomSelect()
      },
      updateDomSelect(){
          // UPDATE INDEX TO SELECTS
          this.updateSelect("#big_a",this.buttons.big_a)
          this.updateSelect("#big_b",this.buttons.big_b)
          this.updateSelect("#small_a",this.buttons.small_a)
          this.updateSelect("#small_b",this.buttons.small_b)
      },
      updateSelect(key,val){
          this.$$(key +' .smart-select')
              .find('.item-after').text(val)
              .next().children('option')
              .each(function(index,element){
                  if (element.value == val){
                      //console.log(key + "-> item-> "+ index +" OK");
                      element.selected = true
                      return false
                  }
              })
      }
    },
    watch: {
        buttons: {
            handler: function (val, oldVal) {
                if (this.init) return false
                //console.log('new: %s, old: %s', val, oldVal)
                Utils.setSettingsButtons(JSON.stringify(this.buttons))
                // TWO WAYS/SYNC TO PARENT
                this.$emit("update:mapping", this.buttons)
            },
            deep: true
        }
    }
}
