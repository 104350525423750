//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Utils from '../web_app_mobile/js/utils';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  data: function () {
    return {
      wizUserCode: '',
    }
  },
  mounted() {
    this.$f7ready((f7) => {
      console.log('F7Ready app_mobile');
    })
  },
  methods: {
    ...mapActions(['authenticate']),
    onLoginOpen(e) {},
    submitCodeForm(e){
      /** FORM ACTION 1 **/
      const self   = this
      let params = Utils.getDataToCreate()
      params.data.code = this.wizUserCode

      this.authenticate(params).then( response =>
      {
        //YA HEMOS CARGADO COUNTER EN BASE, RELOAD
        this.$f7.views.main.router.refreshPage()
      }).catch( error =>
      {
        console.log(error);
        this.$f7.dialog.alert(this.$t("main_vue.dialog.invalid_login"));
      });
    },
    onClickPrivacy(e){},
    onLoginClose() {
       /**  RESET FORM FIELDS ON CLOSED  **/
       console.log('closed login...');
       this.wizUserCode = ''
    }
  },

  computed: {
    ...mapState(['isLoggedIn','sessionExit','threadSync','uuid']),
    openlogin() {
      return (!this.isLoggedIn);
    }
  }
}
