import Vue from 'vue'
import Vuex from 'vuex'
import Framework7 from 'framework7/framework7.esm.bundle';
import Api from './utils/api.js'
import Timer from '../js/Timer.js';
import Utils from '../js/utils';
import i18n  from "../i18n";

import * as Sentry from '@sentry/browser';


Api.baseUrl         =  window.App.config.host;

// TYPES

export const IS_LOGGED_IN     = 'IS_LOGGED_IN';
export const SESSION_EXIT     = 'SESSION_EXIT';
export const SET_DB           = 'SET_DB';
export const UUID             = 'UUID';
export const SYNC_RUNNING     = 'SYNC_RUNNING';
export const LAST_BATCH_ID    = 'LAST_BATCH_ID';
export const SET_AFORAMENT_COUNTER = 'SET_AFORAMENT_COUNTER';
export const SET_BADGET_COUNT = 'SET_BADGET_COUNT';
export const LAST_UPDATE      = 'LAST_UPDATE';


Vue.use(Vuex,Framework7);

const state = {
  db: false,                                             // Database conection LOCALSTORAGEDB
  threadSync: new Timer(),                               // Timer object, ( backup)
  ajaxSyncRunning: false,                                // If ajax backup is running or not, state
  isLoggedIn: !!localStorage.getItem("token"),      // If exist a valid token
  sessionExit: false,                                    // If session forced exit 401
  uuid: 'ui-null',                                       // Device uuid
  lastBatchId: 0,                                        // Last batch created
  last_updated_at: null,
  aforamentCounter: 0,                                   // Current counter from active service of "aforament"
  badgeMessages: 0,                                      // Counter from messages to icon button at services
}

const actions = {
   // ASYNC
   authenticate({commit}, params ){

     const self = this;
     self.dispatch('preload');

     return new Promise((resolve, reject) => {

       Api.authenticate(params)
         .then(response => {
             // Persisting user authentication(token)
             Utils.setTokenAuth(response.authenticate.token);
             // Persisting session(token)
             commit('IS_LOGGED_IN',true);
             commit('SESSION_EXIT',false);
           resolve(response);
         }).catch(xhr => {
           console.log('Error: Api.authenticate ->' + xhr.status);
           reject(xhr.status);
         });
     });

   },
   controls({commit}, params ){
    const self = this;
        return new Promise((resolve, reject) => {

          Api.controls(params, Utils.getTokenAuth())
              .then(response => {
                resolve(response);
              }).catch(xhr => {
            console.log('Error: Api.controls ->' + xhr.status);
            reject(xhr.status);
          });
        });
    },
   insertRow(context, params) {
        const self = this;
        return new Promise((resolve, reject) => {
            try {
                context.state.ajaxSyncRunning = true
                const id = context.state.db.insert("counts", params)
                context.state.db.commit()
                context.state.ajaxSyncRunning = false
                resolve(id)
            } catch (e) {
                context.state.ajaxSyncRunning = false
                console.log(e.message)
                resolve(false)
            }
        });
    },
    getAllRows(context) {
        const self = this;
        return new Promise((resolve, reject) => {
            try {
                const rows = context.state.db.queryAll("counts");
                context.state.db.deleteRows("counts")
                context.state.db.commit()
                resolve(rows)
            } catch (e) {
                console.log("getAllRows",e.message)
                resolve([])
            }
        });
    },
    addCount(context,params) {
        return Api.addCount(params,Utils.getTokenAuth())
    },
   preload(context, open, F7 ){

     F7 = F7 || App.$f7;
     if(open==undefined||open==null) open = true;

     if (open) {
       F7.preloader.show();
     } else {
       F7.preloader.hide();
     }
   },
   logoutSession(context){
     return new Promise((resolve, reject) => {
       context.commit('IS_LOGGED_IN', false);
       localStorage.removeItem("token");
       resolve();
     });
   },
    lastBatchDate({commit}){
        const now = new Date();
        commit("LAST_UPDATE",i18n.t('control_counters_vue.lastBatchUpdated',{ now: now.toLocaleTimeString()}))
    },
}

const mutations = {
  // ALWAYS SYNC

  [IS_LOGGED_IN](state, status) {
    state.isLoggedIn = status; //Vue.set(state, 'isLoggedIn', status)
  },
  [LAST_UPDATE](state, status) {
    state.last_updated_at = status; //Vue.set(state, 'isLoggedIn', status)
  },
  [SESSION_EXIT](state, status) {
    state.sessionExit = status;//Vue.set(state, 'sessionExit', status)
  },
  [SET_DB](state, status) {
    state.db= status;//Vue.set(state, 'db', status)
  },
  [UUID](state, status) {
    state.uuid= status;//Vue.set(state, 'uuid', status)
  },
  [SYNC_RUNNING](state, status) {
    state.ajaxSyncRunning= status;//Vue.set(state, 'ajaxSyncRunning', status)
  },
  [LAST_BATCH_ID](state, status) {
    state.lastBatchId= status;//Vue.set(state, 'lastBatchId', status)
  },
  [SET_AFORAMENT_COUNTER](state, status) {
    state.aforamentCounter= status;//Vue.set(state, 'aforamentCounter', status)
  },
  [SET_BADGET_COUNT](state, status) {
    state.badgeMessages= status;//Vue.set(state, 'badgeMessages', status)
  }
}

const getters = {
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
