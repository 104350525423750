
import Home from '../web_app_mobile/pages/home.vue';
import CountersFree from '../web_app_mobile/pages/counters_free.vue';
import PanelLeftPage from '../web_app_mobile/pages/panel-left.vue';


export default [
  {
    path: '/',
    component: Home,
    alias: '/init/'
  },
  {
    path: '/counters/',
    name: 'counters_free',
    component: CountersFree
  },
  {
    path: '/panel-left/',
    component: PanelLeftPage
  }
];
