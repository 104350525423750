import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ca', //window.navigator.language,
  fallbackLocale: 'ca',
  messages: {
    'en': require('./en.json'),
    'es': require('./es.json'),
    'ca': require('./ca.json')
  }
});

if (module.hot) {
  module.hot.accept(['./en.json','./es.json', './ca.json'], () => {
    i18n.setLocaleMessage('en', require('./en.json'));
    i18n.setLocaleMessage('es', require('./es.json'));
    i18n.setLocaleMessage('ca', require('./ca.json'));
    console.log('hot reload i18n', this, arguments);
  });
}

export default i18n;
