//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapMutations, mapActions, mapState} from 'vuex'
import Utils from "../js/utils";
import ControlSheetSettings from "./control-sheet-settings";
export default {
    name: "control-counters-free",
    components: {ControlSheetSettings},
    props: ['control'],
    data(){
        return{
            timer : null,
            hidden: true,
            lastCounter: 0,
            aforamentCounter: 0,
            buttons:{}
        }
    },
    mounted() {
        this.$f7ready(f7 =>{
            this.lastCounter = parseInt(Utils.getAforamentCount())
            this.aforamentCounter = 0
        })
    },
    methods:{
        ...mapActions(['preload','insertRow','addCount','lastBatchDate']),
        ...mapMutations(['IS_LOGGED_IN']),

        resetCurrentCounter(){
            this.aforamentCounter = 0
        },
        getNowUnixEpoch(){
            let dateTime = new Date().getTime(), timestamp = Math.floor(dateTime / 1000);
            return timestamp;
        },
        uniqueNumber() {
            return this.$uuidKey(); //Plugin
        },
        addCounter(e){
            // RESET TIMEOUT
            this.hidden = false
            clearTimeout(this.timer)
            const val = parseInt(e.target.dataset.count)
            this.aforamentCounter+= val
            this.lastCounter = this.aforamentCounter
            Utils.setAforamentCount(this.aforamentCounter)
            this.timerToAddDB()
        },
        timerToAddDB(){
          const self = this
          this.timer = setTimeout(async ()=>{
            const row = {
              mobile_database_id: this.uniqueNumber(),
              quantity: this.aforamentCounter,
              date: this.getNowUnixEpoch()
            }

            let params = Utils.getDataToCreate()
            params.data.mobile_database_id = row.mobile_database_id
            params.data.quantity           = row.quantity
            // SEND COUNT
            this.addCount(params).then(response => {
              self.$parent.$parent.$parent.getControl(false)
            }).catch(xhr => {
              // ADD ROW TO BACKUP IF FAILS
              self.insertRow(row)
            })
            this.resetCurrentCounter()
            this.hidden = true

          },1000)
        }

    },
    computed:{
        ...mapState([
            'uuid',
            'isLoggedIn',
            'db',
            'last_updated_at'
        ])
    },
    filters:{
        formatNumber: value =>{
            return (value >=0  && value < 10)? "0"+value: value;
        },
        mathSymbols: value =>{
            return (value >=0)? "+" + value : value;
        }
    },
    watch: {
    }
}
