//==== CSS ENTRY POINT - BASE ======
import Vue from 'vue'
// Import Framework7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import Framework7-Vue with all components
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
// Import F7 Style
import Framework7CSS from 'framework7/css/framework7.bundle.min.css';
// Import F7 Icons
import Framework7Icons from 'framework7-icons/css/framework7-icons.css';
// MAIN THEME
import '../css/web_app_mobile.scss'
// Language
import i18n from '../src/components/web_app_mobile/i18n'
// Import main components
import App from '../src/components/web_app_mobile/app_mobile.vue'
// Import Vuex Storage
import store from '../src/components/web_app_mobile/vuex/storage';
// Worker & Utils
import Utils from '../src/components/web_app_mobile/js/utils';
// Import Routes
import routes from "../src/components/web_app_mobile/routes";
// Unique key uuidv4
import uuid from '@estudioliver/vue-uuid-v4';
// Mappers es6
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
// DB
import localStorageDB from "localstoragedb"

// Init plugin and register all components
Vue.use(uuid);
Framework7.use(Framework7Vue);

document.addEventListener('DOMContentLoaded', () => {
    window.App = new Vue({
        render: h => h(App),
        i18n,
        store,
        data(){
            return {
                f7params: {
                    name: "Cultura Mataró",
                    id: 'AJCounter',
                    theme: 'md', // auto
                    xhrCache: false,
                    init    : true, // auto-init, after checks
                    initOnDeviceReady: true,
                    sheet: {
                        closeByBackdropClick: false
                    },
                    dialog:{
                        title: this.$t("commons.app_name"),
                        buttonOk: this.$t("main.dialog.buttonOk"),
                        buttonCancel: this.$t("main.dialog.buttonCancel")
                    },
                    navbar:{
                        iosCenterTitle: true,
                        mdCenterTitle: true
                    },
                    routes: routes
                },
                lockSync: false
            }
        },
        mounted() {
            const self = this
            this.$f7ready(  (f7) => {
                console.log("$f7ready main.js")
                this.$$(window).on('ajaxcompleted:api',this.onCompletedAjaxApi);
                setTimeout(()=>{
                    f7.loginScreen.get('#lg-screen').on('open',self.onLoginScreenOpen);
                    self.installDB()
                    self.validateAndRedirect()
                },500)
            });
        },
        methods:{
            ...mapActions(['preload',  'logoutSession', 'getAllRows','addCount']),
            ...mapMutations(['SYNC_RUNNING', 'SET_DB']),
            onCompletedAjaxApi(){
                console.log('AJAX completed')
                this.preload(false)
            },
            onLoginScreenOpen(e){
                console.log('login ON')
                if(this.sessionExit){
                    e.app.notification.create({
                        title: this.$t("commons.app_name"),
                        text: this.$t("main.notification.text"),
                        closeTimeout: 3000,
                    }).open();
                }
            },
            installDB(){
                    try{
                        const db = new localStorageDB("counter", localStorage);
                        // Check if the database was just created. Useful for initial database setup
                        if( db.isNew() ) {
                            console.log("DB created")
                            // Schema DB
                            db.createTable("counts", ["mobile_database_id", "quantity", "date"]);
                            if (!db.commit()){
                                throw new Error("Invalid DB Commit")
                            }
                        }else{
                            console.log("DB online")
                        }
                        this.SET_DB(db)
                        // EXECUTE BACKUPS TO COUNT
                        this.installChronBackup()

                    }catch (e) {
                        this.$f7.dialog.alert(this.$t("commons.application_error"))
                    }

            },
            installChronBackup(){

                const self = this;
                this.threadSync.every('5 seconds', async ()=>{
                    let   rows  = []
                    console.log('Tasker running ( 5 seconds)');

                    // IF AJAX IS REQUESTING OR NOT LOGGED, RETURNED
                    if (self.ajaxSyncRunning || !self.isLoggedIn || self.lockSync) return false;

                    try{
                        const rows = await self.getAllRows()
                        console.log("Count->rows", rows.length)
                        if (rows.length == 0) return false;
                        self.lockSync = true
                        await Utils.asyncForEach(rows,async (row)=>{
                            try{
                                console.log("UP --> row",row)
                                let params = Utils.getDataToCreate()
                                params.data.mobile_database_id = row.mobile_database_id
                                params.data.quantity = row.quantity
                                await self.addCount(params)
                            }catch (e) {
                                rows.push(row) // SI FALLA METO EN ARRAY
                            }
                        })
                        self.lockSync = false
                    }catch (e) {
                        self.lockSync = false
                       // TODO: Debería meter los rows en la DB ?
                    }finally {
                        // ACTUALITZEM ELS CONTROLS
                        if (rows.length > 0) self.$root.$children[0].$children[0].$children[1].$children[0].getControl(false)
                    }

                });

            },
            /**
             * VALIDA SI TE UN TOKEN I REDIRECCIONA AL COMPTADOR
             */
            validateAndRedirect(){
                if(Utils.getTokenAuth()){
                    this.$f7.views.main.router.navigate("/counters/", {reloadAll: true, ignoreCache: true});
                }
            },
            closeApp(){},
            logout() {
                this.logoutSession()
                    .then(() => {
                        /**
                         * FORCE WORKERS TO STOP AT LOGOUT
                         */
                        if (this.threadSync.running()) {
                            console.log("Running DOWN")
                            this.threadSync.stop();
                        }
                        /** GO TO HOME **/
                        //this.$f7.views.main.router.navigate("/home", {reloadAll: true, ignoreCache: true});
                    }).catch(err => {
                        this.$f7.dialog.alert(this.$t('main.dialog.closeSess.error'));
                });
            },
        },
        computed:{
            ...mapState(['threadSync','isLoggedIn','ajaxSyncRunning'])
        }
    }).$mount()
    document.body.appendChild(window.App.$el)
})
