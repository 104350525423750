import Framework7 from 'framework7/framework7.esm.bundle';
import * as Sentry from '@sentry/browser';

const Api = {

   baseUrl: window.App.config.host || "http://localhost:3000",

   authenticate: http_params =>{
       const config = Framework7.utils.extend({url:Api.baseUrl + '/api/v1/sessions/log_in', method:'POST'}, http_params);
       return Api.send(config);
   },
   controls: (http_params, token) =>{
        const session = {data: { token: token}}
        const config = Framework7.utils.extend({url:Api.baseUrl + '/api/v1/controls',method:'GET'},session, http_params);
        //if (token) config.headers = { 'Authorization': token };
        return Api.send(config);
   },
   addCount: (http_params, token) =>{
        const session = {data: { token: token}}
        const config = Framework7.utils.extend({url:Api.baseUrl + '/api/v1/controls/add_count/',method:'POST'}, session, http_params);
        return Api.send(config);
   },
   send: (params) => {
     const $$ = Framework7.Dom7;
     return new Promise((resolve, reject) => {
      try {
        const config = Framework7.utils.extend(
          {
            method    : 'POST',
            cache     : false,
            dataType  : 'json',
            timeout   : 25000,
            success   : (data, status, xhr) => {
              resolve(data) // return
            },
            error : (xhr, status) => {

              const exclude = /^(401|405|406|204|0)$/
              if (!exclude.test(xhr.status)) {

                Sentry.captureMessage('API error status->' + status, {
                  extra: {api: params}
                });

              }

              reject(xhr) // return
            },
            complete : (xhr, status) => {

              $$(window).trigger('ajaxcompleted:api');
            }
          },
          params);

        Framework7.request(config);

      } catch (e) {

        Sentry.captureException(e , {
          extra:{api: params}
        });

        $$(window).trigger('ajaxcompleted:api')
        console.log(e.message)
        reject(e.message)

      }

     });
   }

};

export default Api
