import store  from '../vuex/storage'
const UTILS = {

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  },
  /**
   *
   * @param commit
   * @param exit  bool
   */
  setActions401(commit, exit){
    if (exit == undefined) exit = true
    localStorage.removeItem("token");
    commit('SESSION_EXIT',exit);
    commit('IS_LOGGED_IN', false);

  },
  getLoginScreen() {
    return App.$f7.loginScreen.get('.login-screen')
  },
  getMessagePopup(){
    return App.$children[0].$refs.controlmessages;
  },
  getTokenAuth() {
    return localStorage.getItem("token") || false  //this.getSettingDB("token") || false
  },
  setTokenAuth(token) {
    return localStorage.setItem("token",token)
  },

  getAforamentCount(){
    return localStorage.getItem("aforament_count") || 0
  },
  setAforamentCount(val){
    if (val === '') return false;
    localStorage.setItem("aforament_count", val);
  },

  setSettingsButtons(val){
    console.log("settings value", val)
    if (!val) return false;
    localStorage.setItem("settings_buttons", val);
  },
  getSettingsButtons(){
    try {
      return (JSON.parse(localStorage.getItem("settings_buttons")) || false )
    }catch (e) {
      console.error(e)
      return false
    }
  },
  getDataToCreate(){
    return {data:{}}
  },
  getFlash(key){
    if (!key) return false;
    const val = localStorage.getItem("flash_" + key);
    localStorage.removeItem("flash_" + key);
    return val
  },
  setFlash(key,val){
    if (!val || !key) return false;
    localStorage.setItem("flash_" + key, val);
  },
  // Return nested values from object like literal "prop.prop.pro"
  getSafe(nestedObj, pathString){
    const pathArr = pathString.split('.')
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
  },
  createBlobWorker(worker){
    try {
      return window.URL.createObjectURL(
        new Blob([document.querySelector(worker).textContent], { type: "text/javascript" })
      );
    }catch (e) {
      console.error(e)
     return null
    }
  }

}

export default UTILS;
