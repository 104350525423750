//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapMutations, mapActions, mapState} from 'vuex'
import Utils from "../js/utils";
import ControlCountersFree from "../components/control-counters-free";
import i18n from "../i18n";

export default {
    components: {ControlCountersFree},
    data() {
        return {
          service_name:  "- SERVEI -",
          control: {}
        }
    },
    mounted() {
        this.$f7ready(f7 => {
          this.lastBatchDate()
        })
    },
    methods:{
        ...mapActions(['preload','controls','lastBatchDate']),
        ...mapMutations(['IS_LOGGED_IN']),
        promptLogout(e){
          this.$f7.dialog.confirm(this.$t("counters_free.prompt_logout"), ()=>{
            // OK LOGOUT
            this.$root.logout()
          })
        },
        getControl(loader=true){
          if (Utils.getTokenAuth()){
            // CARREGEM DADES DE CONTROL SI HEM FET LOGIN
            if(loader) this.preload()
            this.controls({}).then( response =>
            {
              this.control = response.authenticate || {}
              this.service_name = response.authenticate.service_name || "---"
              this.lastBatchDate()
            }).catch( error =>
            {
              console.log(error);
            });
          }
        },
        onPageInit(e) {
          this.getControl()
          if (Utils.getTokenAuth()) {
            if (!this.threadSync.running()) {
              console.log("Running UP")
              this.threadSync.start();
            }
          }
        }
    },
    computed: {
        ...mapState([
            'uuid',
            'isLoggedIn',
            'threadSync'
        ])
    },
};
